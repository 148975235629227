import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="container d-flex justify-content-center">
      <div className="position-relative">
        <header>
          <img src="/landing.png" className="img-fluid" alt="Header"/>
        </header>
        <div className="content-container">
          <main>
            <h1>Welcome to the most powerful faith and bible AI assistant available!</h1>
            <p>Faith Navigator utilizes AI to help you navigate the most important aspect of your life.
                COMING SOON.
            </p>
            <div className="d-grid gap-2">
              <Link to="/privacy-policy" className="btn btn-primary">Privacy Policy</Link>
              <Link to="/terms-of-service" className="btn btn-primary">Terms of</Link>
              <a href="mailto:support@zupko.org" className="btn btn-secondary">Contact Support</a>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Home;
